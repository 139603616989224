.confusion-matrix-container{
    border:1px solid #696969;
    width: fit-content;
    height:auto;
}
.confusion-matrix-y-axis-container {
    display: flex;
    flex-direction: column;
    height:auto;
    min-height: 300px;
}
.confusion-matrix-x-axis-container{
    display:flex;
    flex-wrap: nowrap;
    flex: 1 1 auto;
    /*background:white;*/
}

.matrix-container-box {
    min-width: 45px;
    min-height: 46px;
    width: 55%;
    align-items: center;
    justify-content: center;
    padding: 0;
    display: flex;
    flex-grow: 1;
    box-shadow: 0 0 2px #cdcdcd;
}
.matrix-container-box.rotate-label{
    transform: rotate(270deg);
    overflow: hidden;
    width: fit-content;
    position: relative;
    right: 22px;
    top: 45px;
    font-size: var(--normal-font-size);
}
.confusion-matrix-predict-label-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.confusion-matrix-actual-label-container{
    display: flex;
    flex-wrap: nowrap;
    height: 100%;
    margin-bottom: 10px;
    margin-top: 5px;
}
.confusion-matrix-vertical-container {
    display: flex;
    flex-direction: column;
    /*width: 100%;*/
    height: fit-content;
    min-width: 600px;
    font-size: var(--normal-font-size);
}

.confusion-matrix-overall-container {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: auto;
    justify-content: center!important;
    gap: 10px;
}

.confusion-matrix-overall-container.multi-column-matrix{
    justify-content: flex-start;
}

.confusion-matrix-show-data-container{
    padding: 0;
}

.confusion-matrix-show-color-bar {
    width: 30px;
    max-height: 65vh;
    box-shadow: 0 1px 0 #cdcdcd;
    background: white;
    font-size: var(--normal-font-size);
}

.confusion-matrix-color-bar{
    width: 100%;
    height: 100%;
}

.confusion-matrix-show-color-range {
    display: flex;
    flex-direction: column;
    max-height: 65vh;
    text-align: center;
    top: 5px;
    width:50px;
    justify-content: space-between;
    font-size: var(--normal-font-size);
}

.confusion-matrix-show-color-range label{
    margin-left: 5px;
    display:flex;
    text-align:center;
    margin-top:0;
    font-size: var(--normal-font-size);
}

#confusion-matrix-actual-label{
    display: block;
    font-size: var(--normal-font-size);
}

.confusion-matrix-label-container{
    display: flex;
    align-items: center;
    justify-content:center;
    position: relative;
}

.confusion-matrix-predict-label {
    width: 30px;
    white-space: nowrap;
    align-items: center;
    justify-content: center;
    display: flex;
    top: calc((100% - 200px) / 2);
    transform: rotate(270deg);
    position: relative;
    margin-bottom: 100px;
}
.confusion-matrix-predict-label.less-column{
    top:calc((100% - 70px) / 2) !important;
}
.confusion-matrix-actual-label{
    width: 100%;
    text-align:center;
    margin-top: 50px;
}
.confusion-matrix-empty-container{
    width: 350px;
    height: 350px;
    margin: auto;
    display:flex;
    flex-direction: column;
    justify-content: center;
}
.confusion-matrix-empty-image {
    width: 250px;
    height: 250px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}
.empty-confusion-matrix-text{
    width: 100%;
    text-align: center;
}
.confusion-matrix-image{
    width: 100%;
    height: 100%;
}
.correlation-matrix-box{
    min-width: 150px;
    min-height: 45px;
    height: 45px;
    width: 30%;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    padding: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-grow: 1;
    flex-grow: 1;
}
.confusion-matrix-show-data-container::-webkit-scrollbar{
    width: 5px;
}
.confusion-matrix-show-data-container::-webkit-scrollbar-thumb{
    background: #cdcdcd;
    width: 2px;
    margin: 0 auto;
    border-radius: 5px;
}
.confusion-matrix-show-data-container::-webkit-scrollbar-track{
    background: transparent;
}
.matrix-label-container-box{
    align-items: center;
    justify-content: flex-start;
    padding: 0 10px 0 0;
    display: flex;
    flex-grow: 1;
    font-size: var(--normal-font-size);
}

.confusion-matrix-table-container {
    height: 60vh;
    background: white;
}
.confusion-matrix-table-horizontal-container {
    width: 800px;
    display: flex;
    height: 100%;
}

.confusion-matrix-table-horizontal-container.multi-column-matrix{
    width: 100%;
}

.confusion-matrix-vertical-actual-container{
    min-width: 100px;
    background: #f2f2f2;
    width: auto;

}
.matrix-actual-label-box{
    min-width: 100px;
    background: #f2f2f2;
    height: 10vh;

}
.multi-matrix-actual-label {
    min-width: 100px;
    max-width: 200px;
    height: 60px;
    width: -moz-fit-content;
    width: fit-content;
    align-items: center;
    justify-content: flex-start;
    padding: 20px 10px 0 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: sticky;
    left: 0;
    border-right: 1px solid #cdcdcd;
    background: white;
    color: black;
    font-size: var(--normal-font-size);
}

.confusion-matrix-vertical-data-container {
    height: 100%;
    overflow: auto;
}
.confusion-matrix-vertical-data-container::-webkit-scrollbar{
    width: 5px;
    height:8px;
}
.confusion-matrix-vertical-data-container::-webkit-scrollbar-thumb{
    background: #cdcdcd;
    width: 2px;
    height: 3px;
    margin: 0 auto;
    border-radius: 5px;
}
.confusion-matrix-vertical-data-container::-webkit-scrollbar-track{
    background: #f5f7f9;
}
.confusion-matrix-overall-container::-webkit-scrollbar{
    width: 5px;
    height:8px;
}
.confusion-matrix-overall-container::-webkit-scrollbar-thumb{
    background: #cdcdcd;
    width: 2px;
    height: 3px;
    margin: 0 auto;
}
.confusion-matrix-overall-container::-webkit-scrollbar-track{
    background: transparent;
}

.confusion-matrix-horizontal-data-container{
    display: flex;
    width: fit-content;
}
.multi-matrix-info-container{
    width: 100px;
    height: 60px;
    align-items: center;
    justify-content: center;
    padding: 0;
    display: flex;
    flex-grow: 1;
    text-shadow: 0 0 20px #ffffff;
    box-shadow: 0 0 1px 0 #cdcdcd;
    border-top: 1px solid #e9e9e9;
}

.multi-matrix-actual-value-container{
    /*width: calc(100% - 200px);*/
    display: flex;
}

.confusion-matrix-horizontal-predict-container {
    height: 100px;
    display: flex;
    width: -moz-fit-content;
    width: fit-content;
    position: sticky;
    bottom: 0;
    background: #f5f7f9;

}

.multi-matrix-predict-label {
    width: 100px;
    overflow: hidden;
    align-items: center;
    text-overflow: ellipsis;
    text-align: left;
    white-space: nowrap;
    justify-content: flex-start;
    transform: rotate(270deg);
    padding-left: 10px;
    padding-top: 34px;
    box-shadow: inset -1px 0 0 #cdcdcd;
    color: black;
    background: white;
    font-size: var(--normal-font-size);


}

.multi-col-matrix-color-bar-container{
    width: 30px;
    height: 60vh;
    margin-left: 20px;
    background: white;
}
.multi-col-matrix-color-bar {
  width: 100%;
    height: 100%;
    border: 1px solid #cdcdcd;

}

.multi-col-matrix-color-range {
    display: flex;
    flex-direction: column;
    text-align: center;
    height: 60vh;
    justify-content: space-between;
}
.multi-col-matrix-color-range label{
    margin-left: 5px;
    display:flex;
    text-align:center;
    margin-top:0;
    font-size: var(--normal-font-size);
}
.multi-class-matrix-label{
    width: 100%;
    text-align: center;
    height: 35px;
    padding-left: 100px;
    margin-top: 20px;
    font-size: var(--normal-font-size);
}
.matrix-predict-rotate-label{
    writing-mode: vertical-rl;
    text-orientation: mixed;
    min-width: 45px;
    min-height: 46px;
    width: 30%;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    display: flex;
    flex-grow: 1;
    text-shadow: 0 0 20px #ffffff;
    font-size: var(--normal-font-size);
}
.matrix-container-box.predict-label{
    border:none;
    box-shadow: none;
    font-size: var(--normal-font-size);
}
.confusion-matrix-color-range-container{
    display: flex;
    flex-direction: column;
    position: relative;
    margin-left: 25px;
}
.confusion-matrix-color-label-name{
    margin-bottom: 5px;
    font-size: var(--normal-font-size);
}

.confusion-matrix-center-container {
    position: relative;
    width: calc(100% - 200px) !important;
}

.confusion-matrix-horizontal-predict-container-hider{
    background: white;
    width: 200px;
    position: sticky;
    left: 0;
    z-index: 99;
}
.one-click-model-accuracy-detail-body{
    height: calc(85vh - 20px);
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px){
    .confusion-matrix-overall-container{
        width: 700px;
    }

    .multi-matrix-predict-label{
        font-size: var(--normal-font-size);
    }
}

.matrix-predict-rotate-label{
    font-size: var(--normal-font-size);
}