/*//////////////////////////////// sys Default Popup ///////////////////////////////////////*/

.sys-popup-layout{
    min-width: 400px;
    background: white;
    border-radius: 10px;

}

.sys-popup-header{
    width: 100%;
    padding-left: 20px;
    padding-right: 10px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    background: var(--body-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.sys-popup-icon{
    width: 35px;
    height: 35px;
    align-items: center;
    display: flex;
    justify-content: center;
    fill: white;
    padding: 5px;
}
.sys-popup-title{
    line-height: 50px;
    color:white;
    font-size: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    min-width: 10px;
    max-width: calc(100% - 100px);
}

.sys-popup-menu-bar{
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.notification-dot{
    position: absolute;
    width: 5px;
    height: 5px;
    background: red;
    border: none;
    border-radius: 50%;

}

.sys-popup-empty-frame{
    width: 100%;
    height: 64vh;
    position: relative;
}

.sys-popup-body-container{

}


.sys-popup-body{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    height: auto;


}

.sys-popup-bottom-row{
    width: 350px;
    max-width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right:auto;
    padding-bottom: 2vh;
    margin-bottom: 15px;
}

.sys-popup-bottom-row button{
    width: 100px;
    height: 35px;
    border-radius: 3px;
    margin-top: auto;
    margin-bottom: auto;
}

.sys-popup-cancel-btn{
    background: white;
    border: 1px solid #cdcdcd;
    color: var(--primary-text-color)

}

.sys-popup-cancel-btn:hover{
    background-color: aliceblue;
}

.sys-popup-submit-btn{
    background: var(--primary-color);
    border: 1px solid var(--primary-color);
    color: white;
}

.sys-popup-submit-btn:hover{
    background-color: var(--lighter-primary);
}

.sys-popup-submit-btn:disabled,
.sys-popup-cancel-btn:disabled,
.sys-popup-submit-btn:disabled:hover,
.sys-popup-cancel-btn:disabled:hover{
    background-color: #fafafa;
    border:1px solid #cdcdcd;
    color: #cdcdcd;
}

.sys-popup-bottom-row button:hover{

}

.sys-popup-header-left{
    display: flex;
    align-items: center;
    gap: 5px;
    width: 90%;
}

.sys-popup-main-menu-bar{
    position: absolute;
    /* top: 0; */
    right: 10px;
    display: flex;
    z-index: 999;
    align-items: center;
    height: 50px;
    gap: 5px;
}



.sys-popup-main-menu{
    min-width: 30px;
    height: 30px;
    background: transparent;
    border: none;
    color: white;
    white-space: nowrap;
    fill: white;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;


}

.sys-popup-main-menu:hover{
    background: rgba(0,0,0,0.1);
}

.sys-popup-main-menu:active{
    background: transparent;
}

.sys-popup-main-menu:disabled{
    filter:grayscale(100%);

}
.sys-popup-main-menu:disabled:hover{
    filter:grayscale(100%);
    background: transparent;
}

.sys-popup-main-menu_custom{
    display: flex;
    align-items: center;
    justify-content: center;
    fill: #123f76;
    color: #123f76;
    background: #d3ecfd;
    border-radius: 3px;
    gap: 3px;
    height: 30px;
    padding: 0px 7px;
    border: none;

}

.sys-popup-main-menu_custom:hover{
    background: white;
    box-shadow: 0 0 2px aliceblue;
}

.sys-popup-main-menu_custom:disabled{
    opacity: 0.4;
}