
#instant-droppable-zone{
    position: absolute;
    width: 90%;
    height: 90%;
    z-index: 99;
    display: none;
    top: 15px;
    left: 15px;
    background: white;
}
.zip-file-drop-screen{
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.zip-file-drop-title{
    fill: #696969;
}
.image-preview-table-text-name{
    width: calc(100% - 50px);
}
.audio-container{
    display: flex;
    justify-content: space-between;
}


.upload-zip-file-input-box{
    opacity: 0;
    position: absolute;
    width: 203px;
    height: 40px;
    border-radius: 3px;
    cursor: pointer;
    top: 175px;
    left: 110px;
}
.upload-zip-file-input-box::-webkit-file-upload-button {
    visibility: hidden;
}

.upload-zip-file-input-box:before{
    content: "";
    border: none;
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    cursor: pointer;
    border-radius: 5px;
    position: absolute;
    width: 100%;
    height: 100%;
}
#instant-droppable-zone {
    display: none;
}

#instant-drop-zone{
    border: 2px dashed #cdcdcd;
}

.drop-file-icon{
    fill:#2151A4;
}