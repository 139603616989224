.jf-table-row{
    background: white;
    border-radius: 5px;
    width:98%;
    margin-left:auto;
    margin-right:auto;
    font-size: var(--normal-font-size);
}
.header-cell {
    background: var(--body-color);
    color: var(--light-bg);
    height: 100%;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: row;
    white-space: nowrap;
    flex-wrap: nowrap;
    flex-grow: 1;
    font-size: var(--normal-font-size);
}


.header-cell-value{
    color: var(--primary-color);
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: row;
    white-space: nowrap;
    flex-wrap: nowrap;
    font-size: var(--normal-font-size);
}

.header-cell-name {
    height: 100%;
    width: 100%;
    -ms-word-wrap: break-word;
    word-wrap: break-word;
    word-break: break-all;
    padding-left: 5px;
    padding-right: 5px;
    flex-grow: 1;
    font-size: var(--normal-font-size);
}

.column--data-preview{
    font-size: var(--normal-font-size);
}